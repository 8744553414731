<template>
	<div>
		<TopNav></TopNav>
		<Header></Header>
	
		<div class="Wrap">
			
			
			<div class="UrHere" v-if="CouponList.length > 0" style="padding-top: 10px;color: rgba(0,0,0,0.4);">
				商城优惠券:
			</div>
			
		
	
		</div>
		
		<div class="Wrap" v-if="CouponList.length == 0" style="background-color: rgba(126,168,118,1);margin-top: 20px;">
			<div style="padding: 100px 0px;text-align: center;display: flex;align-items: center;justify-content: center;width: 100%;color: rgba(255,255,255,0.8);font-size: 1.2rem;">
					<span class="el-icon-money"></span>当前尚无开放中可领取的优惠券
			</div>
			
		</div>
		<div class="Wrap" v-if="CouponList.length > 0">
			<div class="Coupons">
				
				<div class="Coupon" v-for="coupon in CouponList" :key="coupon.Id">
					
					<div class="CouponBorder">
						
						<div class="Money">
							<div class="Ico">
								<span class="el-icon-money"></span>
							</div>
							<div class="Inf">
								<div class="Lab">￥</div>
								<div class="Price">{{coupon.Money}}</div>
							</div>
						</div>
						
						<div class="Info">
							<ul>
								<h3>{{coupon.Name}}</h3>
								<p>使用范围:{{coupon.ShopName}}</p>
								<p>使用条件:满￥{{coupon.MoneyStart}}减￥{{coupon.Money}}</p>
								<p>领取时间:{{coupon.StartAt2}} 至 {{coupon.EndAt2}}</p>
							</ul>
						</div>
						
						<div class="Action">
							
							<a class="Get" @click="CoupponApply(coupon.Id)">领取</a>
							
						</div>
						
					</div>
					
				</div>
				
				
			</div>
		</div>
		
		
		
		<Footer></Footer>
		
	</div>
	
</template>

<script>
	import {  Checkbox,Radio,Select,Option,Upload } from 'element-ui';
	export default {
	  name: 'CouponIndex',
	  props: {
	    msg: String
	  },
	  data() {
	      return {
			  Search:{
			  				  Status:-1,//全部
			  				  Id:'',//商品ID
			  				  Name:'',//关键词
			  },
			  CouponList:[],
			  Page:1,
			  PageSize:15,
			  Total:0,
			  IsApply:false
	      }
	    },
	  components: {
		  "el-radio":Radio,
		  "el-select":Select,
		  "el-option":Option,
		  "el-upload":Upload,
		  "el-checkbox":Checkbox
	  },
	  computed:{
	  },
	  created() {
		  this.StartSeaching()
	  },
	  methods:{
		  handleCurrentChange(val){
		  			 this.GetList(val)
		  },
		  StartSeaching(){
		  			  this.GetList(this.Page)
		  			  this.GetCount()
		  },
		  GetCount(){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Coupon',
		  			Action: 'Count',
					Status:50,
					FromCouponChannel:'front',
		  		}
				
				if(this.$route.query.id != undefined){
					data.Id = this.$route.query.id
				}
				if(this.$route.query.shop_id != undefined){
					data.ShopId = this.$route.query.shop_id
				}
				
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetList(_page){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Coupon',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
		  			Status:50,
		  			FromCouponChannel:'front',
		  		}
				if(this.$route.query.id != undefined){
					data.Id = this.$route.query.id
				}
				if(this.$route.query.shop_id != undefined){
					data.ShopId = this.$route.query.shop_id
				}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					this.CouponList = res.Data.CouponList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  CoupponApply(_id){
			  
			  if(this.IsApply){ //防抖
				  return
			  }
			  this.IsApply = true
			  
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Coupon',
		  			Action: 'Apply',
		  			Id:_id,
		  		}

		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
					this.IsApply = false
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			
					this.$message.success('领取成功')
		  			
		  		})
		  		.catch(function (response) {
					this.IsApply = false
		  			this.$message('网络请求错误')
		  		})
		  },
	  }
	}
</script>

<style scoped>
.Help{
	display: flex;
	width: 100%;
	margin-top: 10px;
}
.Help .Cates{
	width: 260px;
	background-color: #FFFFFF;
	padding-top: 20px;
	padding-bottom: 20px;
}
.Help .Cates h4{
	margin: 0px;
	padding: 0px 0px 0px 20px;
}
.Help .Cates div{
	padding: 10px;
}
.Help .Cates div ul{
	margin: 0px;
	padding: 0px;
}
.Help .Cates div ul li{
	cursor: pointer;
	padding: 3px 10px 3px 40px;
}
.Help .Cates div ul li:hover,.Help .Cates div ul li.On{
	color: rgba(23,134,65,1);
}

.Help .Content{
	flex: 1;
	margin-left: 20px;
	padding: 20px;
	background-color: #FFFFFF;
	display: flex;
}
.Help .Content .Desc{
	width: 800px;
}
.Help .Content .Desc .Detail{
}
.Help .Content .Guide{
	border-left: 1px solid rgba(0,0,0,0.04);
}
.Help .Content .Guide p{
	padding-left: 10px;
	color: rgba(0,0,0,0.4);
}
.Help .Content .Guide li{
	margin-top: 10px;
	padding-left: 10px;
}
.Help .Content .Guide li span{
	cursor: pointer;
}
.Help .Content .Guide li span:hover{
	color: rgba(23,134,65,1);
}
.Coupons{
	margin-top: 10px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.Coupons .Coupon{
	margin-bottom: 20px;
	width: 48%;
	height: 180px;
	background-color: #ffffff;
	padding: 5px;
	border: 2px dotted rgba(126,168,118,1);
}
.Coupons .Coupon .CouponBorder{
	background-color: rgba(126,168,118,1);
	display: flex;
	width: 100%;
	height: 100%;
}
.Coupons .Coupon .CouponBorder .Money{
	display: flex;
	align-items: center;
	height: 100%;
	margin-left: 20px;
	margin-right: 40px;
	color: #ffffff;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}
.Coupons .Coupon .CouponBorder .Money .Ico{
	width: 100%;
	font-size: 2.0rem;
}
.Coupons .Coupon .CouponBorder .Money .Inf{
	display: flex;
	align-items: center;
}
.Coupons .Coupon .CouponBorder .Money .Lab{
}
.Coupons .Coupon .CouponBorder .Money .Price{
	font-size: 2.0rem;
}
.Coupons .Coupon .CouponBorder .Info{
	flex: 1;
	display: flex;
	align-items: center;
	height: 100%;
	flex-wrap: wrap;
	margin-right: 20px;
}
.Coupons .Coupon .CouponBorder .Info h3{
	width: 100%;
	padding: 0px;
	margin: 0px;
	color: #ffffff;
	font-size: 1.3rem;
}
.Coupons .Coupon .CouponBorder .Info p{
	width: 100%;
	margin: 0px;
	padding: 0px;
	margin-top: 5px;
	color: rgba(255,255,255,0.8);
}
.Coupons .Coupon .CouponBorder .Action{
	display: flex;
	align-items: center;
	height: 100%;
	margin-right: 20px;
}
.Coupons .Coupon .CouponBorder .Action .Get{
	background-color: rgba(255,255,255,0.8);
	width: 80px;
	height: 80px;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.6rem;
	color: rgba(126,168,118,1);
	cursor: pointer;
}
.Coupons .Coupon .CouponBorder .Action .Get:hover{
	background-color: rgba(255,255,255,1);
}
</style>
